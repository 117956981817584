<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <cmsHeader />
    <Toast />
    <div class="container">
      <div class="formcontainer form-horizontal" v-if="trainer.imageFilename">
        <h1>Trainer : Edit </h1>
        <p>Make the required changes to the Trainer.  <b>Please note:</b> that once saved, ANY event that has this trainer, will also have the latest information.</p>
        
        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="name"> Name <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="name" class="formwidthmedium form-control" v-model="trainer.name" size="30" />
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="title"> Title <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="Title" class="formwidthmedium form-control" v-model="trainer.title" size="60" />
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="location"> Location <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <InputText name="location" class="formwidthmedium form-control" v-model="trainer.home" />
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="biography">Biography <span class="required">*</span> : </label>
          <div class="col-xs-12 col-sm-8">
            <Textarea name="biorgraphy" v-model="trainer.biography" rows="5" cols="120" :autoresize="true"/>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="trainerimage">Current Photo: <span class="required">*</span> : </label>
          <img class="profile-image" name="trainerimage" :src="baseURL + trainerImagesPath + trainer.imageFilename">
        </div>

        <div class="form-group form-group-sm">
          <label class="col-xs-12 col-sm-2 control-label" for="trainerimage">Replace Photo: <span class="required">*</span> : </label>
          <div class="upload-button"><FileUpload 
            mode="basic"
            name="files[]"
            :auto="true"
            :url="`${baseURL}api/v1/cms/trainer/resource/image/add`" 
            @error="uploadError" 
            @upload="uploadComplete" 
            :disable="true"
          /></div>
        </div>

        <div class="form-group">
          <label>&nbsp;</label>
          <div class="formwidthmedium"><button type="submit" class="btn-orange btn btn-default" @click="updateTrainer()">Submit</button></div>
      </div>

      </div>
    </div>
    <cmsFooter />
  </div>
</template>

<script>
// 3rd Party Libraries and Helpers
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRoute } from 'vue-router'

// Schema
import Trainer from '../../../schemas/Trainer.js'

// Components
import cmsHeader from '../../../components/cms/cmsHeader.vue'
import cmsFooter from '../../../components/cms/cmsFooter.vue'

// API Services
import TrainingServices from '../../../services/TrainerServices.js'

// Primevue
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import FileUpload from 'primevue/fileupload'

export default {
  name: 'cmsTrainerEdit',
  components: {
    cmsHeader,
    cmsFooter,
    InputText,
    Textarea,
    FileUpload
  },
  setup() {
    const toast = useToast()
    const route = useRoute()
    const baseURL = process.env.VUE_APP_API_SERVER
    const environment = process.env.VUE_APP_ENVIRONMENT
    const trainerImagesPath = process.env.VUE_APP_TRAINER_IMAGES
    const currentImageFilename = ref('')
    const trainer = ref(new Trainer())

    TrainingServices.getTrainingResource(route.query.tID)
    .then((response) => {
      trainer.value = response.data
      currentImageFilename.value = trainer.value.imageFilename
    })
    .catch(() => {
      toast.add({severity: 'error', summary: 'Error', detail: 'Could not get the training resource requested!', life: 5000})
    })

    const updateTrainer = () => {
      TrainingServices.updateTrainingResource(trainer.value)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity: 'info', summary: 'Information', detail: response.data, life: 5000})
        } else {
          toast.add({severity: 'success', summary: 'Success', detail: `Training resource ${trainer.value.name} has been updated!`, life: 5000})
        }
      })
      .catch(() => {
        toast.add({severity: 'error', summary: 'Error', detail: 'Training resource could not be updated!', life: 5000})
      })
    }

    const uploadComplete = (data) => {
      const result = JSON.parse(data.xhr.response)
      if(currentImageFilename.value) { TrainingServices.deleteImageFromFileSystem(currentImageFilename.value) }
      trainer.value.imageFilename = result.filename[0]
      currentImageFilename.value = trainer.value.imageFilename
    }

    const uploadError = () => {
      toast.add({severity:'error', summary: 'Upload Error', detail:'Failed to upload Image!', life: 5000})
    }

    return {
      toast,
      confirm,
      baseURL,
      environment,
      trainer,
      trainerImagesPath,
      updateTrainer,
      uploadComplete,
      uploadError
    }
  }
}
</script>

<style lang="scss" scoped>
.sizes {
  .p-inputtext {
    display: block;
    margin-bottom: .5rem;

    &:last-child {
        margin-bottom: 0;
    }
  }
}
.field {
  display: flex;
  flex-direction: column nowrap;
  justify-content: flex-start;
  background-color: lightgrey;
}
.profile-image {
  display: flex;
  flex-direction: column nowrap;
  max-width: 10%;
  min-width: 10%;
  border-color: Green;
  border-style: solid;
  border-width: 1px;
  border-radius: 75px;
  background-color: green;
}
.upload-button {
  display: flex;
  flex-direction: column nowrap;
  justify-self: start;
}
</style>
